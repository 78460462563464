import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import { useTheme } from '../contexts/ThemeContext';
import '../css/AnimatedThemeToggleButton.css'; // Import CSS file

const ThemeToggleButton = () => {
  const { mode, toggleTheme } = useTheme();

  return (
    <Tooltip title="Thème">
      <IconButton
        onClick={toggleTheme}
        className={`animated-button ${mode === 'light' ? 'light' : 'dark'} pulse`}
        color="inherit"
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
            color: 'inherit',
            border: 'none',
            outline: 'none',
            marginLeft:1,
          },
          '&:active': {
            backgroundColor: 'transparent',
            color: 'inherit',
            border: 'none',
            outline: 'none',
            marginLeft:1,
          },
          '&:focus': {
            outline: 'none',
            marginLeft:1,
          },
          border: 'none',
          borderRadius: '50%', // Assurez-vous que le bouton est rond
          padding: 0, // Ajustez le padding selon vos besoins
          marginLeft:1,
        }}
      >
        {mode === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
      </IconButton>
    </Tooltip>
  );
};

export default ThemeToggleButton;
