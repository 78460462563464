import React from 'react';
import { Box, Typography, Grid, Button, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import image1 from '../assets/images/fruity.png';
import image2 from '../assets/images/quintellix.png';
import image3 from '../assets/images/montdoc.png';
import image4 from '../assets/images/budgetMaster.png';
import image5 from '../assets/images/todolist.png';

// Composant de carte de projet
const ProjectCard = ({ title, imageUrl, liveDemoUrl }) => {
  const theme = useTheme(); // Utiliser le thème Material-UI pour les couleurs
  const { t } = useTranslation(); // Initialiser useTranslation pour la traduction

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '8px',
        padding: 2,
        textAlign: 'center',
        width: '100%',
        maxWidth: '300px',
        margin: 'auto',
      }}
    >
      <Box
        component="img"
        src={imageUrl}
        alt={title}
        sx={{
          borderRadius: '16px',
          width: '90%',
          height: 'auto',
          margin: '0 auto',
        }}
      />
      <Typography variant="h6" sx={{ margin: '1rem', color: theme.palette.text.primary }}>
        {title}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="outlined"
          sx={{
            color: theme.palette.text.primary,
            borderColor: theme.palette.divider,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          }}
          href={liveDemoUrl}
          target="_blank"
        >
          {t('view_project')} {/* Utilisation de la fonction de traduction */}
        </Button>
      </Box>
    </Box>
  );
};

// Composant pour afficher la section des projets
const ProjectsSection = () => {
  const { t } = useTranslation();

  const projects = [
    {
      title: t('budgetMaster'),
      imageUrl: image4,
      liveDemoUrl: 'https://expensetracker.lowewilliam.com/',
    },
    {
      title: t('mytaskmanager'),
      imageUrl: image5,
      liveDemoUrl: 'https://mytaskmanager.lowewilliam.com/',
    },
    {
      title: t('company_website'),
      imageUrl: image2,
      liveDemoUrl: 'https://quintellixwebsite.netlify.app/',
    },
    {
      title: t('fruit_sales_platform'),
      imageUrl: image1,
      liveDemoUrl: 'https://fruity-delights.lowewilliam.com/',
    },
    {
      title: t('mail_management_app'),
      imageUrl: image3,
      liveDemoUrl: 'https://mondoc.netlify.app/',
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t('projects_title')}</title>
        <meta name="description" content={t('projects_description')} />
        <meta name="keywords" content="William Lowe, projets, développement web, cybersécurité, projets informatiques, réalisations" />
        <meta property="og:title" content={`${t('projects_intro')} ${t('name')}`} />
        <meta property="og:description" content={t('projects_description')} />
        <meta property="og:url" content="https://lowewilliam.com/" />
      </Helmet>

      <Box sx={{ padding: 4 }}>
        <Typography variant="h3" sx={{ marginBottom: 6, borderBottom: '3px solid green', display: 'inline-block' }}>
          {t('Title_projects')}
        </Typography>
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          {t('recent_projects')}
        </Typography>
        <Grid container spacing={4}>
          {projects.map((project, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <ProjectCard
                title={project.title}
                imageUrl={project.imageUrl}
                liveDemoUrl={project.liveDemoUrl}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default ProjectsSection;
