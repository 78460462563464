import React from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import {
  Code,
  Storage,
  PhoneIphone,
  DesignServices,
  People,
  Build,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const services = [
  {
    key: 'front_end',
    icon: <Code fontSize="large" />,
  },
  {
    key: 'back_end',
    icon: <Storage fontSize="large" />,
  },
  {
    key: 'mobile',
    icon: <PhoneIphone fontSize="large" />,
  },
  {
    key: 'web_design',
    icon: <DesignServices fontSize="large" />,
  },
  {
    key: 'team_supervision',
    icon: <People fontSize="large" />,
  },
  {
    key: 'devops',
    icon: <Build fontSize="large" />,
  },
];

const Services = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h3" sx={{ marginBottom: 4, borderBottom: '3px solid green', display: 'inline-block' }}>
        {t('services_title')}
      </Typography>
      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                transition: '0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
                textAlign: 'center',
              }}
            >
              <CardContent>
                <Box sx={{ marginBottom: 2 }}>
                  <Box
                    sx={{
                      fontSize: '40px',
                      color: 'green',
                      transition: '0.3s',
                      '&:hover': {
                        transform: 'scale(1.2)',
                      },
                    }}
                  >
                    {service.icon}
                  </Box>
                </Box>
                <Typography variant="h6">{t(`services_${service.key}_title`)}</Typography>
                <Typography variant="body2">{t(`services_${service.key}_description`)}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Services;
