import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ThankYou = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        padding: 4,
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: 2, color: 'green' }}>
        {t('thank_you_message')}
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 4, textAlign: 'center' }}>
        {t('thank_you_info')}
      </Typography>
      <Button variant="contained" color="success" component={Link} to="/">
        {t('return_home')}
      </Button>
    </Box>
  );
};

export default ThankYou;
