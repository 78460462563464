import React from 'react';
import { Box, Typography, Paper, Button, LinearProgress, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next'; // Importez useTranslation pour la gestion des traductions
import '../css/About.css'; // Assurez-vous d'importer votre feuille de style

const About = () => {
  const { t } = useTranslation(); // Utilisez useTranslation pour obtenir les traductions

// List of skills
const skills = t('skills_detail', { returnObjects: true });
// List of education
const education = t('educations', { returnObjects: true });
// List of professional experiences
const experience = t('experience_items', { returnObjects: true })

// About component to display information about me

  const handleDownloadClick = () => {
    // Path to the PDF file in the public folder
    const link = document.createElement('a');
    link.href = '/WILLIAM-LOWE.pdf';
    link.download = 'WILLIAM-LOWE.pdf';
    link.click();
  };

  return (
    <>
      <Helmet>
        <title>{t('about_title')}</title>
        <meta name="description" content={t('description')} />
        <meta name="keywords" content="William Lowe, cybersecurity, web development, skills, education, professional experience" />
        <meta property="og:title" content={t('about_title')}  />
        <meta property="og:description" content={t('description')} />
      </Helmet>

      <Box sx={{ padding: 4 }}>
        <Typography variant="h3" sx={{ marginBottom: 4, borderBottom: '3px solid green', display: 'inline-block' }}>{t('about')}</Typography>

        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          {t('about_intro')} <span style={{ color: "green", fontSize: "25px" }}>{t('about_future_intern')}</span>
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          {t('about_description')}
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>{t('skills')}</Typography>
            {skills.map((skill) => (
              <Box key={skill.name} sx={{ marginBottom: 2 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                  {t(skill.name)} <span style={{ color: 'primary', float: 'right' }}>{skill.experience}</span>
                </Typography>
                <LinearProgress
                  sx={{
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: 'green',
                    },
                    backgroundColor: 'lightgray',
                  }} variant="determinate" value={skill.level} />
              </Box>
            ))}
            <Button onClick={handleDownloadClick} variant="contained" color="success" sx={{ marginTop: 2, marginBottom: 3 }}>{t('download_cv')}</Button>

            <Paper sx={{ padding: 2, marginBottom: 4 }}>
              <Typography variant="h6" sx={{ marginBottom: 2 }}>{t('education')}</Typography>
              <div className="timeline">
                {education.map((edu) => (
                  <Box key={edu.degree} className="timeline-item" sx={{ marginBottom: 2 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{edu.date}</Typography>
                    <Typography variant="body1">{edu.degree}</Typography>
                    {edu.description && <Typography variant="body2">{edu.description}</Typography>}
                    <Typography variant="body2" sx={{ fontStyle: 'italic' }}>{edu.institution}</Typography>
                  </Box>
                ))}
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6} sx={{ marginTop: 4 }}>
            <Paper sx={{ padding: 2 }}>
              <Typography variant="h6" sx={{ marginBottom: 4 }}>{t('Experience')}</Typography>
              <div className="timeline">
                {experience.map((exp) => (
                  <Box key={exp.role} className="timeline-item" sx={{ marginBottom: 4 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{exp.date}</Typography>
                    <Typography variant="body1">{exp.role}</Typography>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{exp.company}</Typography>
                    <Typography variant="body2">{exp.description}</Typography>
                  </Box>
                ))}
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default About;
