import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import './i18n'; 
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import About from './Components/About';
import Services from './Components/Service';
import Projects from './Components/Projets';
import Contact from './Components/Contact';
import ThankYou from './Components/ThankYou';
import News from './Components/News';
import NotFound from './Components/NotFound';
import useAnalytics from './Components/useAnalytics';




const App = () => {
  return (
    <Router>
      <AnalyticsWrapper />
    </Router>
  );
};

const AnalyticsWrapper = () => {
  useAnalytics();

  return (
    
    <Box sx={{ display: 'flex' }}>
       <Navbar />
      
      <CssBaseline />
     
      <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/thankyou" element={<ThankYou />} />
          <Route path="/News" element={<News />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default App;
